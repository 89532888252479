import { useContext } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, FormattedPlural } from "react-intl";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

const StopoverFlightDurationLabel = ({
	stopOverDayCount,
	stopOverCountry,
	shorten = false,
	className,
}) => {
	const { resolution } = useContext(AppGlobalsContext);
	const isMedium = resolution === RESOLUTION.MEDIUM;
	return (
		<Typography
			variant={TYPOGRAPHY_VARIANTS.SMALL}
			{...{ className }}
			isBold
			data-testid="stopover-flight-duration-label"
		>
			<FormattedPlural
				value={stopOverDayCount}
				one={
					<FormattedMessage
						values={{
							days: (
								<FormattedMessage
									values={{ duration: stopOverDayCount }}
									id="general.duration.days.singular"
								/>
							),
							country: stopOverCountry,
						}}
						id={
							shorten && isMedium
								? "stop.over.flight.duration.label"
								: "stop.over.flight.duration.label.long"
						}
					/>
				}
				other={
					<FormattedMessage
						values={{
							days: (
								<FormattedMessage
									values={{ duration: stopOverDayCount }}
									id="general.duration.days.plural"
								/>
							),
							country: stopOverCountry,
						}}
						id={
							shorten && isMedium
								? "stop.over.flight.duration.label"
								: "stop.over.flight.duration.label.long"
						}
					/>
				}
			/>
		</Typography>
	);
};

StopoverFlightDurationLabel.propTypes = {
	className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	stopOverDayCount: PropTypes.number.isRequired,
	stopOverCountry: PropTypes.string.isRequired,
	shorten: PropTypes.bool,
};

export default StopoverFlightDurationLabel;
